<template>
  <div class="">
    <promo-section
      class="promo--blog"
      :subtitle="promo.subtitle"
      :title="promo.title"
      :image="promo.image"
      :button="promo.button"
    />
    <posts-section />
  </div>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import PostsSection from '@/components/blog-page/PostsSection.vue';

export default {
  name: 'Blog',
  components: {
    PromoSection,
    PostsSection,
  },
  data() {
    return {
      promo: {
        subtitle: 'Press',
        title: 'Innovation is what we do.</br> <span class="text-accent">Keep an eye on what’s next.</span>',
        button: {
          caption: 'READ OUR LATEST',
          link: '#latest',
          route: true,
        },
      },
    };
  },
};
</script>
