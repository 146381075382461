<template>
  <div
    class="posts"
    id="latest"
  >
    <div class="posts__container container">
      <h2 class="posts__title heading" v-html="title"/>
      <div class="posts__row">
        <div
          v-for="post in posts"
          :key="post.id"
          class="posts__col"
        >
          <post-card class="posts__card" :post="post"/>
        </div>
        <cta-banner
          class="posts__cta"
          :title="banner.title"
          :text="banner.text"
          type="newsletter"
        />
      </div>
      <div class="posts__actions">
        <main-button class="posts__button btn btn--link" >
          LOAD OLDER ARTICLES
        </main-button>
      </div>
    </div>
  </div>
</template>

<script>
import PostCard from '@/components/blog-page/PostCard.vue';
import postImage1 from '@/assets/images/post-image-1.jpg';
import postImage2 from '@/assets/images/post-image-2.jpg';
import CtaBanner from '@/components/CtaBanner.vue';

export default {
  name: 'PostsSection',
  components: { CtaBanner, PostCard },
  data() {
    return {
      title: 'Latest Articles',
      banner: {
        title: 'Signup for our newsletter.',
        text: 'Getting coverage through CoverWhale. No biggie. Even our call to actions are witty.',
      },
      posts: [
        {
          id: 1,
          image: postImage1,
          title: 'Nasdaq to Spin Out Market for Pre-IPO Shares in Deal',
          excerpt: `
            <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley to spin out its marketplace for shares of private companies.</p>
            <p>The deal could help drive more transactions to Nasdaq Private Market, the New York-based exchange operator’s trading platform for shares of companies that haven’t yet had an initial public offering...</p>
           `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 2,
          image: postImage2,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Travelers – The insurance company earned $3.45 per share for its second quarter, easily beating the consensus estimate of $2.39. Revenue also topped forecasts, with Travelers benefiting from higher premiums, improved investment returns and lower catastrophe losses. </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 3,
          image: postImage2,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Travelers – The insurance company earned $3.45 per share for its second quarter, easily beating the consensus estimate of $2.39. Revenue also topped forecasts, with Travelers benefiting from higher premiums, improved investment returns and lower catastrophe losses. </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 4,
          image: postImage1,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Travelers – The insurance company earned $3.45 per share for its second quarter, easily beating the consensus estimate of $2.39. Revenue also topped forecasts, with Travelers benefiting from higher premiums, improved investment returns and lower catastrophe losses. </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 5,
          image: postImage2,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley... </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 6,
          image: postImage1,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley... </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 7,
          image: postImage1,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley... </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 8,
          image: postImage2,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley... </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 9,
          image: postImage1,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley... </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 10,
          image: postImage2,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley... </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";

.posts {
  padding: 100px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $md) {
    padding: 35px 0 25px;
  }

  &__title {
    @include breakpoint(0, $lg) {
      display: none;
    }

  }

  &__row {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    //grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    align-items: start;
    align-content: start;

    @include breakpoint(0, $lg) {
      margin-bottom: 30px;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(0, $md) {
      grid-template-columns: 1fr;
      margin-bottom: 20px;
      grid-gap: 20px;
    }
  }

  &__col {

    &:nth-child(1) {
      grid-column: 1 / 3;

      @include breakpoint(0, $lg) {
        grid-column: 1 / -1;
      }

      :deep {
        .post__image {
          min-height: 400px;

          @include breakpoint(0, $md) {
            min-height: 340px;
          }
        }
      }

    }

    &:nth-child(2) {
      grid-column: 3 / 5;
      @include breakpoint(0, $lg) {
        grid-column: 1 / -1;
      }
      :deep {
        .post__image {
          min-height: 400px;

          @include breakpoint(0, $md) {
            min-height: 340px;
          }
        }
      }
    }

  }

  &__cta {
    grid-area: 3 / 1 / 4 / -1;
    margin-bottom: 0;
    padding: 0;

    @include breakpoint(0, $lg) {
      grid-row: 5 / 6;
    }

    :deep {
      .cta-banner__container {
        padding: 0;
      }
    }
  }

  &__actions {
    text-align: center;
  }

  &__button {
    text-transform: uppercase;
    color: $blue;
    font-size: 13px;
    text-decoration: underline;
  }

}
</style>
